<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('elearning_config.vanue_info') }}  {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('elearning_config.vanue_name')"
              label-for="venu_name"
            >
              <b-form-input
                id="venu_name"
                v-model="search.venu_name"
                placeholder=""
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('elearning_config.vanue_info') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(training_type)="data">
                      <span class="capitalize">{{ data.item.training_type }}</span>
                    </template>
                    <template v-slot:cell(training_category)="data">
                      <span class="capitalize">{{ data.item.training_category }}</span>
                    </template>
                    <template v-slot:cell(contact_person_no)="data">
                      {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.contact_person_no, { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(status)="data">
                        <span class="badge badge-success" v-if="data.item.status === 1">{{$t('globalTrans.active')}}</span>
                        <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                        <!-- <b-button class="mr-1" title="View Details" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                        <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 border" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                        <b-button :variant="data.item.status === 1 ? ' iq-bg-success border' : '  iq-bg-danger border'" size="sm" @click="remove(data.item)">
                            <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                        </b-button> -->
                        <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 @click="details(data.item)"><i class="fas fa-eye"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_status" title="Status" v-if="data.item.status === 1" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" v-if="data.item.status === 2" @click="remove(data.item)"><i class="fa fa-toggle-off"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
    <b-modal id="modal-5" size="lg" :title="$t('elearning_config.vanue_management_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
       <Details :item="item" :key="id"></Details>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import FormV from './Form'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { vanueManagementList, vanueManagementStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
import Details from './Details'

export default {
  name: 'UiDataTable',
  mixins: [ModalBaseMasterList],
  components: {
    FormV, Details
  },
  data () {
    return {
      search: {
        org_id: 0,
        office_type_id: 0,
        office_id: 0,
        venu_name: ''
      },
      id: 0,
      item: ''
    }
  },
  computed: {
     ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    trainingTypeList () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    },
    organizationtList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('elearning_config.vanue') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.vanue') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('org_pro.organization'), class: 'text-center' },
          { label: this.$t('org_pro.office_namel'), class: 'text-center' },
          { label: this.$t('elearning_config.vanue_name'), class: 'text-center' },
          // { label: this.$t('elearning_config.contract_person_no'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'office_name_bn' },
          { key: 'venu_name_bn' },
          // { key: 'contact_person_no' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'office_name' },
          { key: 'venu_name' },
          // { key: 'contact_person_no' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
  },
  mounted () {
    this.search = Object.assign({}, this.search, {
      org_id: this.$store.state.dataFilters.orgId
      // office_type_id: this.$store.state.dataFilters.officeTypeId,
      // office_id: this.$store.state.dataFilters.officeId
    })
    core.index()
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    details (item) {
      this.item = item
    },
    remove (item) {
      this.changeStatus(trainingElearningServiceBaseUrl, vanueManagementStatus, item)
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(trainingElearningServiceBaseUrl, vanueManagementList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getRelationalData(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const fiscalList = this.$store.state.commonObj.fiscalYearList
      const orgList = this.$store.state.commonObj.organizationProfileList
      const officeTypeList = this.$store.state.commonObj.officeTypeList
      const officeList = this.$store.state.commonObj.officeList
      const listData = data.map(item => {
        const fiscalObj = fiscalList.find(fiscal => fiscal.value === item.fiscal_year_id)
        const orgObj = orgList.find(org => org.value === item.org_id)
        const officeTypeObj = officeTypeList.find(officeType => officeType.value === item.office_type_id)
        const officeObj = officeList.find(officeType => officeType.value === item.office_id)
        const fiscalData = { fiscal_name: fiscalObj !== undefined ? fiscalObj.text_en : '', fiscal_name_bn: fiscalObj !== undefined ? fiscalObj.text_bn : '' }
        const orgData = { org_name: orgObj !== undefined ? orgObj.text_en : '', org_name_bn: orgObj !== undefined ? orgObj.text_bn : '' }
        const officeTypeData = { officeType_name: officeTypeObj !== undefined ? officeTypeObj.text_en : '', officeType_name_bn: officeTypeObj !== undefined ? officeTypeObj.text_bn : '' }
        const officeData = { office_name: officeObj !== undefined ? officeObj.text_en : '', office_name_bn: officeObj !== undefined ? officeObj.text_bn : '' }
        return Object.assign({}, item, orgData, officeTypeData, officeData, fiscalData)
      })
      return listData
    }
  }
}
</script>
