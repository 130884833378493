<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                        <b-col lg="12" sm="12">
                            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                                    <b-row>
                                        <b-col lg="6" sm="6">
                                            <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="fiscal_year_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="venueManagement.fiscal_year_id"
                                                    :options="fiscalYearList"
                                                    id="fiscal_year_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="Organization" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="org_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('org_pro.organization')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="venueManagement.org_id"
                                                    :options="orgList"
                                                    :disabled="!$store.state.Auth.activeRoleId || $store.state.Auth.activeRoleId !== 1"
                                                    id="org_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="Office Type" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="office_type_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('org_pro.office_type')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="venueManagement.office_type_id"
                                                    :options="officeTypeList"
                                                    id="office_type_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="Office" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="office_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('org_pro.office_namel')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="venueManagement.office_id"
                                                    :options="officeList"
                                                    id="office_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="Vanue Name (En)" vid="venu_name" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="venu_name"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('elearning_config.vanue_name_en') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="venu_name"
                                                    v-model="venueManagement.venu_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="Vanue Name (Bn)" vid="venu_name_bn" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="venu_name_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('elearning_config.vanue_name_bn') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="venu_name_bn"
                                                    v-model="venueManagement.venu_name_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="12" sm="12" style="margin-left: 15px;" v-if="venueManagement.org_id === 12">
                                            <ValidationProvider name="Check Condition" vid="it_cafeteria">
                                                <b-form-group
                                                    class="row"
                                                    label-for="it_cafeteria"
                                                    slot-scope="{ errors }"
                                                >
                                                    <b-form-checkbox
                                                        id="it_cafeteria"
                                                        v-model="venueManagement.it_cafeteria"
                                                        value="1"
                                                        unchecked-value="0"
                                                    >
                                                        {{$t('elearning_config.it_cafeteria')}}
                                                    </b-form-checkbox>
                                                    <div class="invalid-feedback d-block">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <!-- <b-col lg="6" sm="12">
                                            <ValidationProvider name="Contact No" vid="contact_person_no" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="contact_person_no"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('elearning_config.contract_person_no') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    type="number"
                                                    id="contact_person_no"
                                                    v-model="venueManagement.contact_person_no"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col> -->
                                    </b-row>
                                    <b-row>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="Total Capacity" vid="total_capacity" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="total_capacity"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('elearning_config.total_capacity') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    type="number"
                                                    id="total_capacity"
                                                    v-model="venueManagement.total_capacity"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12" v-if="$store.state.Auth.authUser.org_id !== 9 && venueManagement.org_id !== 9">
                                            <ValidationProvider name="Rent Amount" vid="rent_amount" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="rent_amount"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('elearning_config.rent_amount') }} ({{ $t('globalTrans.govt') }})  <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    type="number"
                                                    id="rent_amount"
                                                    v-model="venueManagement.rent_amount"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12" v-if="$store.state.Auth.authUser.org_id == 9 || venueManagement.org_id == 9">
                                            <ValidationProvider name="NARS Institute Rent Amount" vid="nars_org_rent_amount" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="nars_org_rent_amount"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('elearning_config.nars_org_rent_amount') }}<span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    type="number"
                                                    id="nars_org_rent_amount"
                                                    v-model="venueManagement.rent_amount"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                         <b-col lg="6" sm="12" v-if="$store.state.Auth.authUser.org_id !== 9 && venueManagement.org_id !== 9">
                                            <ValidationProvider name="Rent Amount Private" vid="rent_amount_pvt" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="rent_amount_pvt"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('elearning_config.rent_amount') }} ({{ $t('globalTrans.private')}}) <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    type="number"
                                                    id="rent_amount_pvt"
                                                    v-model="venueManagement.rent_amount_pvt"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                         <b-col lg="6" sm="12" v-if="$store.state.Auth.authUser.org_id == 9 || venueManagement.org_id == 9">
                                            <ValidationProvider name="Govt. Organization/Public University Room Rent" vid="gov_org_public_uni_rent_amount" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="gov_org_public_uni_rent_amount"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('elearning_config.gov_org_public_uni_rent_amount') }}<span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    type="number"
                                                    id="gov_org_public_uni_rent_amount"
                                                    v-model="venueManagement.rent_amount_pvt"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                         <b-col lg="6" sm="12" v-if="$store.state.Auth.authUser.org_id == 9 || venueManagement.org_id == 9">
                                            <ValidationProvider name="Private University/Organization Rent Amount" vid="private_org_uni_rent_amount" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="private_org_uni_rent_amount"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('elearning_config.private_org_uni_rent_amount') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    type="number"
                                                    id="private_org_uni_rent_amount"
                                                    v-model="venueManagement.private_org_uni_rent_amount"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                         <b-col lg="6" sm="12" v-if="$store.state.Auth.authUser.org_id == 9 || venueManagement.org_id == 9">
                                            <ValidationProvider name="Institute Scientist/Officer/Employee/Labourer Son/daughter except other use Rent Amount" vid="ins_sci_officer_labour_rent_amount" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="ins_sci_officer_labour_rent_amount"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('elearning_config.ins_sci_officer_labour_rent_amount') }}<span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    type="number"
                                                    id="ins_sci_officer_labour_rent_amount"
                                                    v-model="venueManagement.ins_sci_officer_labour_rent_amount"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                         <b-col lg="6" sm="12" v-if="$store.state.Auth.authUser.org_id == 9 || venueManagement.org_id == 9">
                                            <ValidationProvider name="Only Agricultural Organization Rent Amount" vid="only_agri_org" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="only_agri_org"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('elearning_config.only_agri_org') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    type="number"
                                                    id="only_agri_org"
                                                    v-model="venueManagement.only_agri_org"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12" v-if="$store.state.Auth.authUser.org_id !== 9 && venueManagement.org_id !== 9">
                                            <ValidationProvider name="Electricity Bill" vid="electricity_bill">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="electricity_bill"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('elearning_config.electricity_bill') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    type="number"
                                                    id="electricity_bill"
                                                    v-model="venueManagement.electricity_bill"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                            <b-col lg="6" sm="12" v-if="venueManagement.it_cafeteria == 1 || venueManagement.org_id !== 12 && $store.state.Auth.authUser.org_id !== 9 && venueManagement.org_id !== 9">
                                            <ValidationProvider name="Gas Bill" vid="gas_bill">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="gas_bill"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('elearning_config.gas_bill') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    type="number"
                                                    id="gas_bill"
                                                    v-model="venueManagement.gas_bill"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="Address (En)" vid="address" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="address"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('elearning_config.address_en') }}  <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="address"
                                                    v-model="venueManagement.address"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="Address (Bn)" vid="address_bn"  rules="required" >
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="address_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('elearning_config.address_bn')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="address_bn"
                                                    v-model="venueManagement.address_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="Remkars (En)" vid="remarks">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="remarks"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('elearning_config.remarks_en') }}
                                                    </template>
                                                    <b-form-input
                                                    id="remarks"
                                                    v-model="venueManagement.remarks"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="Remkars (Bn)" vid="remarks_bn">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="remarks_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('elearning_config.remarks_bn')}}
                                                    </template>
                                                    <b-form-input
                                                    id="remarks_bn"
                                                    v-model="venueManagement.remarks_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <div class="row">
                                    <div class="col-sm-3"></div>
                                    <div class="col text-right">
                                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                        &nbsp;
                                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                    </div>
                                    </div>
                                </b-form>
                            </ValidationObserver>
                        </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { vanueManagementStore, vanueManagementUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getEditingData()
      this.venueManagement = tmp
    } else {
        this.venueManagement = Object.assign({}, this.venueManagement, {
            org_id: this.$store.state.dataFilters.orgId
        })
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      loading: false,
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      venueManagement: {
        org_id: 0,
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        venu_name: '',
        venu_name_bn: '',
        address: '',
        address_bn: '',
        // contact_person_no: '',
        rent_amount: '',
        rent_amount_pvt: '',
        gas_bill: '',
        private_org_uni_rent_amount: '',
        ins_sci_officer_labour_rent_amount: '',
        only_agri_org: '',
        electricity_bill: '',
        total_capacity: '',
        remarks: '',
        remarks_bn: '',
        it_cafeteria: 0
      },
      officeTypeList: [],
      officeList: []
    }
  },
  computed: {
    orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    // officeTypeList: function () {
    //     return this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
    // },
    // officeList: function () {
    //     return this.$store.state.commonObj.officeList.filter(item => item.status === 0)
    // },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  watch: {
        'venueManagement.org_id': function (newVal, oldVal) {
            this.officeTypeList = this.getOfficeTypeList(newVal)
        },
        'venueManagement.office_type_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.officeList = this.getOfficeList(newVal)
            }
        }
    },
  methods: {
    addItem () {
      const obj = {
        name_en: '',
        name_bn: '',
        designation_en: '',
        designation_bn: '',
        mobile_no: '',
        email: ''
      }
      const key1 = parseInt(this.venueManagement.details.length - 1)
     const item = this.venueManagement.details[key1]
      let isEmpty = true
      Object.keys(item).map(key => {
          if (item.name_en === '' || item.name_bn === '' || item.designation_en === '' || item.designation_bn === '' || item.mobile_no === '') {
              isEmpty = false
          }
      })
      if (isEmpty === true) {
        this.venueManagement.details.push(obj)
      }
    },
    remove (key) {
      this.venueManagement.details.splice(key, 1)
    },
    getEditingData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.venueManagement.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${vanueManagementUpdate}/${this.id}`, this.venueManagement)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, vanueManagementStore, this.venueManagement)
      }

      loadingState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.commit('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    },
    getOfficeTypeList (orgId = null) {
        const officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
        if (orgId) {
            return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        }
        return officeTypeList
    },
    getOfficeList (officeTypeId = null) {
        const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
        if (officeTypeId) {
            return officeList.filter(office => office.office_type_id === officeTypeId)
        }
        return officeList
    }
  }
}
</script>
